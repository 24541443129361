import { Box, Button, Highlight, Text } from '@chakra-ui/react';

import mail from '@assets/mail.svg';
import ShimmerImage from '@components/common/ShimmerImage';

import styles from '@styles/export.module.scss';

interface Props {
  onVerify: () => void;
}

const VerifyEmail: React.FC<Props> = () => {
  return (
    <Box textAlign="center" width="100%">
      <ShimmerImage src={mail} alt="Mail Icon" width="84px" height="84px" priority />
      <Box py="8" as="h1">
        Xác thực email
      </Box>
      <Text fontWeight="400" fontSize="14" color={styles.colorSecondaryGrey} py="8">
        <Highlight query="anhtran@email.com" styles={{ fontWeight: 'bold' }}>
          Chúng tôi đã gửi một email để xác thực đến anhtran@email.com Hãy kiểm tra hộp thư để xác thực email
        </Highlight>
      </Text>
      <Text fontWeight="400" color={styles.colorSecondaryGrey} pb="8">
        {'Không nhận được email xác thực?'}
        <Button variant="link">Gửi lại</Button>
      </Text>
      <Text fontWeight="400" fontSize={14} color={styles.colorSecondaryGrey}>
        {'Bạn đã có tài khoản? '}
        <Button variant="link">Đăng nhập</Button>
      </Text>
    </Box>
  );
};

export default VerifyEmail;
