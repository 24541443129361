import dynamic from 'next/dynamic';

import { Container, ContainerProps } from '@chakra-ui/react';

import NavBar from '@common/NavBar';

const Footer = dynamic(() => import('@common/Footer'), { ssr: true });

interface Props extends ContainerProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<Props> = ({ children, ...props }) => {
  return (
    <>
      <NavBar />
      <Container as="main" maxW="7xl" flex="1" pt="20" pb="10" {...props}>
        {children}
      </Container>
      <Footer />
    </>
  );
};

export default DefaultLayout;
