import { ApiEnpoints } from '@constants/api';
import { Trip } from '@types';

import HttpClient from './HttpClient';

export interface SearchParams {
  limit?: number;
  startAfterId?: string;
  tags: string[];
  types: string[];
  lowerBoundPrice?: number;
  upperBoundPrice?: number;
}

const defaultSearchParams: SearchParams = {
  limit: 12,
  tags: [],
  types: [],
};

const TripService = {
  getPopulars: async () => {
    const response = await HttpClient.get(ApiEnpoints.trips.populars);
    const { data } = response;
    return data as Trip[];
  },
  getBestOfTheMonth: async () => {
    const response = await HttpClient.get(ApiEnpoints.trips.bestOfTheMonth);
    const { data } = response;
    return data as Trip[];
  },
  search: async (
    query: string,
    { limit, startAfterId, tags, types, lowerBoundPrice, upperBoundPrice }: SearchParams = defaultSearchParams,
  ) => {
    const params = {
      q: query,
      startAfterId: startAfterId,
      limit: limit,
      tag: tags.join(', ') || null,
      type: types.join(', ') || null,
      lowerBoundPrice,
      upperBoundPrice,
    };
    const { data } = await HttpClient.get(ApiEnpoints.trips.search, {
      params,
    });
    return data as Trip[];
  },
  get: async (id: string) => {
    const response = await HttpClient.get(ApiEnpoints.trips.detail(id));
    const { data } = response;
    return data as Trip;
  },
  like: async (id: string) => {
    const response = await HttpClient.put(ApiEnpoints.trips.like(id));
    const { data } = response;
    return data;
  },
  addBookmark: async (id: string) => {
    const response = await HttpClient.put(ApiEnpoints.trips.bookmark(id));
    const { data } = response;
    return data;
  },
};

export default TripService;
