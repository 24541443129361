import { useRouter } from 'next/router';

import { Button, Flex } from '@chakra-ui/react';
import { PlusCircle, Bell, Home } from '@styled-icons/boxicons-regular';
import { Home as HomeSolid, PlusCircle as PlusCircleSolid } from '@styled-icons/boxicons-solid';

import { TabName } from '@constants/index';

const tabItems = [
  {
    id: TabName.home,
    label: 'Trang chủ',
    icon: Home,
    activeIcon: HomeSolid,
    activePath: '/',
  },
  {
    id: TabName.createTrip,
    label: 'Tạo trip',
    icon: PlusCircle,
    activeIcon: PlusCircleSolid,
    activePath: '/create-trip',
  },
  {
    id: TabName.notifications,
    label: 'Thông báo',
    icon: Bell,
    activeIcon: Bell,
  },
];

const NavBarTab: React.FC = () => {
  const router = useRouter();
  return (
    <Flex>
      {tabItems.map((item) => {
        const isActive = item.activePath && router.pathname === item.activePath;
        return (
          <Button
            key={item.label}
            leftIcon={isActive ? <item.activeIcon size="16px" /> : <item.icon size="16px" />}
            minWidth="132px"
            variant="ghost"
            fontWeight="600"
            onClick={() => router.push(item.activePath || '/')}
          >
            {item.label}
          </Button>
        );
      })}
    </Flex>
  );
};

export default NavBarTab;
