import { useMediaQuery } from '@chakra-ui/react';

import { breakpoints } from '../constants';

const useBreakPoints = () => {
  const [isDesktop, isMobile] = useMediaQuery([
    `(min-width: ${breakpoints.tablet}px)`,
    `(max-width: ${breakpoints.mobile}px)`,
  ]);
  const isTablet = !isDesktop && !isMobile;
  return { isDesktop, isTablet, isMobile };
};

export default useBreakPoints;
