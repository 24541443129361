import { useEffect, useState } from 'react';

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { XCircle } from '@styled-icons/boxicons-regular';

import { useAppSelector, useBreakPoints } from '@hooks/index';

import AuthMethod from './AuthMethod';
import SignIn from './SignIn';
import SignUp from './SignUp';
import VerifyEmail from './VerifyEmail';

export enum AuthModalType {
  AuthMethod,
  SignIn,
  SignUp,
  VerifyEmail,
  FinishSignUp,
}

interface Props extends Omit<ModalProps, 'children'> {
  type?: AuthModalType;
}

const AuthModal: React.FC<Props> = (props) => {
  const { type = AuthModalType.AuthMethod } = props;
  const [modalType, setModalType] = useState(type);
  const { isMobile, isDesktop } = useBreakPoints();
  const { authStatus } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (authStatus === 'loggedin') props.onClose();
  }, [authStatus, props]);

  const modalContent = () => {
    switch (modalType) {
      case AuthModalType.AuthMethod:
        return <AuthMethod onLoginWithEmail={() => setModalType(AuthModalType.SignIn)} />;
      case AuthModalType.SignIn:
        return <SignIn onSignUp={() => setModalType(AuthModalType.SignUp)} />;
      case AuthModalType.SignUp:
        return <SignUp onSignIn={() => setModalType(AuthModalType.SignIn)} />;
      case AuthModalType.VerifyEmail:
        return <VerifyEmail onVerify={() => setModalType(AuthModalType.VerifyEmail)}></VerifyEmail>;
    }
  };
  return (
    <Modal {...props} size={isMobile ? 'full' : 'md'}>
      <ModalOverlay />
      <ModalContent {...(isDesktop && { minWidth: '500px' })}>
        <ModalCloseButton size="lg" borderRadius="50%">
          <XCircle size="21.5px" />
        </ModalCloseButton>
        <ModalBody pt="20" pb="10" px="8">
          {modalContent()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;
