import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import slugify from 'slugify';

import { AuthErrors } from '@constants/index';
import { TripType } from '@enums';
import { Trip, TripDestination } from '@types';

dayjs.extend(relativeTime);

export const bytesToMB = (bytes: number) => {
  return bytes / 1024 / 1024;
};

export const getAuthErrorString = (errorCode: string) => {
  const errorString = AuthErrors[errorCode];
  return errorString || 'Đã có lỗi xảy ra, vui lòng thử lại sau';
};

export const formatRelativeDateTime = (date: Date) => {
  return dayjs(date, { locale: 'vi' }).fromNow();
};

export const formatCurrency = (value: number) => {
  return value?.toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'VND',
  });
};

export const formatNumber = (value: number | string) => {
  if (typeof value === 'string') {
    value = parseNumber(value);
  }
  return value ? value.toLocaleString('vi-VN') : '';
};

export const parseNumber = (value: string) => {
  value = value.replaceAll(',', '');
  value = value.replaceAll('.', '');
  return parseInt(value);
};

export const epochToDate = (epoch: number) => {
  return new Date(epoch);
};

export const getTripSlug = (trip: Trip) => {
  const name = trip.name ? `-${slugify(trip.name)}` : '';
  return `${trip.id}${name}`;
};

export const getTripTypeAsString = (trip: Trip) => {
  if (trip.type === TripType.DayTrip) return 'Trong ngày';
  const type = 'Dài ngày';
  let length = '';
  const tripDayLength = trip.length.days;
  const tripNightLength = trip.length.nights;
  if (tripDayLength > 0) length += `${tripDayLength} ngày`;
  if (tripNightLength > 0) length += ` ${tripNightLength} đêm`;
  if (length !== '') length = `(${length})`;
  return `${type} ${length}`;
};

export const getTripImageRoll = (trip: Trip) => {
  const imageRoll = [
    {
      thumbnail: trip.thumbnail,
      original: trip.thumbnail,
    },
  ];
  for (let i = 0; i < 3; i++) {
    if (!trip.destinations[i]) continue;
    const destination = trip.destinations[i] as TripDestination;
    imageRoll.push({
      thumbnail: destination.thumbnail,
      original: destination.thumbnail,
    });
  }
  return imageRoll;
};

export const isLikedTrip = (likeMap?: Record<string, boolean>, uid?: string) => {
  if (!uid || !likeMap) return false;

  return likeMap[uid] || false;
};

export const isBookmarkedTrip = (bookmarkMap?: Record<string, boolean>, uid?: string) => {
  if (!uid || !bookmarkMap) return false;

  return bookmarkMap[uid] || false;
};

type DateType = string | number | Date | Dayjs;

declare module 'dayjs' {
  interface Dayjs {
    fromNow(withoutSuffix?: boolean): string;
    from(compared: DateType, withoutSuffix?: boolean): string;
    toNow(withoutSuffix?: boolean): string;
    to(compared: DateType, withoutSuffix?: boolean): string;
  }
}
