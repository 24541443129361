import {
  browserLocalPersistence,
  browserSessionPersistence,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInAnonymously,
} from 'firebase/auth';

import { firebaseAuth } from './firebase';

export enum LoginType {
  Google = 'google',
  Facebook = 'facebook',
  Email = 'email',
}

export const AuthService = {
  loginSocial: async (type: LoginType) => {
    let provider: GoogleAuthProvider | FacebookAuthProvider | undefined;
    switch (type) {
      case LoginType.Google:
        provider = new GoogleAuthProvider();
        break;
      case LoginType.Facebook:
        provider = new FacebookAuthProvider();
        break;
    }
    if (!provider) return;
    const result = await signInWithPopup(firebaseAuth, provider);
    return result.user;
  },
  signUpWithEmail: async (email: string, password: string) => {
    const result = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    return result.user;
  },
  signInWithEmailAndPassword: async (email: string, password: string) => {
    const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
    return result.user;
  },
  logout: async () => {
    await firebaseAuth.signOut();
  },
  rememberMe: (remember: boolean) => {
    firebaseAuth.setPersistence(remember ? browserLocalPersistence : browserSessionPersistence);
  },
  loginAnonymously: async () => {
    const result = await signInAnonymously(firebaseAuth);
    return result.user;
  },
};
