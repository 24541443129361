export const breakpoints = {
  mobile: 767,
  tablet: 1183,
};

export const TabName = {
  home: 'home',
  createTrip: 'createTrip',
  notifications: 'notifications',
};

export const MINIMUM_PASSWORD_LENGTH = 8;

export const AuthErrors: { [errorCode: string]: string } = {
  'auth/email-already-exists': 'Email này đã được sử dụng',
  'auth/email-already-in-use': 'Email này đã được sử dụng',
};

export const AppName = 'Sherdtrip';

export const tripTravelType: { [id: string]: string } = {
  'xe-may': '🛵 Xe máy',
  'xe-dap': '🚲 Xe đạp',
  'xe-o-to': '🚗 Xe ô tô',
  'xe-bus': '🚌 Xe bus',
  'xe-khach': '🚎 Xe khách',
  'may-bay': '✈️ Máy bay',
  'tau-thuy': '🚢 Tàu thủy',
};

export const transportations = Object.entries(tripTravelType).map(([id, name]) => ({
  label: name,
  value: id,
}));

export const months = [
  { label: 'Tháng 1', value: 1 },
  { label: 'Tháng 2', value: 2 },
  { label: 'Tháng 3', value: 3 },
  { label: 'Tháng 4', value: 4 },
  { label: 'Tháng 5', value: 5 },
  { label: 'Tháng 6', value: 6 },
  { label: 'Tháng 7', value: 7 },
  { label: 'Tháng 8', value: 8 },
  { label: 'Tháng 9', value: 9 },
  { label: 'Tháng 10', value: 10 },
  { label: 'Tháng 11', value: 11 },
  { label: 'Tháng 12', value: 12 },
];

export const DATE_FORMAT = 'DD/MM/YYYY';

export const ImageRatio = {
  landingSection1: 800 / 530,
  landingSection2: 982 / 710,
  landingSection3: 982 / 685,
};

export const maxFileSizeBytes = 5 * 1024 * 1024; // 5MB
