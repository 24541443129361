import { useState } from 'react';

import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useAppDispatch } from '@hooks/index';
import { signIn } from '@redux/authReducer';

import CustomDivider from '@common/CustomDivider';
import Logo from '@common/Logo';
import PasswordInput from '@common/PasswordInput';
import { signInSchema } from '@constants/yupSchema';
import { AuthService } from 'app/firebase/auth';

import styles from '@styles/export.module.scss';

interface Props {
  onSignUp: () => void;
}

const SignIn: React.FC<Props> = (props) => {
  const form = useForm({
    resolver: yupResolver(signInSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting: isLoggingIn },
  } = form;

  const [signInFailed, setSignInFailed] = useState(false);
  const [isRemember, setIsRemember] = useState(true);
  const dispatch = useAppDispatch();

  const handleSignIn = async (data: any = {}) => {
    const { email, password } = data;
    setSignInFailed(false);
    try {
      await dispatch(signIn({ email, password: password ?? '' })).unwrap();
    } catch {
      setSignInFailed(true);
    }
  };

  const handleRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
    AuthService.rememberMe(e.target.checked);
    setIsRemember(e.target.checked);
  };

  const handleSignUp = () => {
    props.onSignUp();
  };

  return (
    <Box textAlign="center" width="100%" as="form" onSubmit={handleSubmit(handleSignIn)}>
      <Center>
        <Logo />
      </Center>
      <Box py="8" as="h1">
        Đăng nhập với Email
      </Box>
      <Flex gap="6" flexDirection="column">
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <Input autoFocus type="email" placeholder="Hãy nhập Email đã đăng ký" {...register('email')} />
          <FormErrorMessage>{errors.email?.message?.toString()}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <FormLabel>Mật khẩu</FormLabel>
          <PasswordInput placeholder="Hãy nhập mật khẩu" {...register('password')} />
          <FormErrorMessage>Vui lòng nhập mật khẩu</FormErrorMessage>
        </FormControl>
        <FormControl textAlign="left">
          <Checkbox defaultChecked isChecked={isRemember} onChange={handleRememberMe}>
            Hãy ghi nhớ tôi
          </Checkbox>
        </FormControl>
        {signInFailed && (
          <FormControl justifyContent="center" display="flex" isInvalid={signInFailed}>
            <FormErrorMessage>Sai Email hoặc mật khẩu </FormErrorMessage>
          </FormControl>
        )}
        <Button width="100%" mb="8" isLoading={isLoggingIn} disabled={isLoggingIn} type="submit">
          Đăng nhập
        </Button>
      </Flex>
      <Button variant="link">Bạn đã quên mật khẩu</Button>
      <CustomDivider />
      <Text color={styles.colorSecondaryGrey}>
        {'Chưa có tài khoản? '}
        <Button variant="link" onClick={handleSignUp}>
          Đăng ký
        </Button>
      </Text>
    </Box>
  );
};

export default SignIn;
