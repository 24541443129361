import { Divider, Flex, Text } from '@chakra-ui/react';

interface Props {
  children?: React.ReactNode;
}

const CustomDivider: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <Flex my="8" align="center">
      <Divider mx="4" />
      {children || <Text>Hoặc</Text>}
      <Divider mx="4" />
    </Flex>
  );
};

export default CustomDivider;
