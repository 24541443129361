import type { AppProps } from 'next/app';

import { ChakraProvider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-multi-carousel/lib/styles.css';
import { Provider } from 'react-redux';

import '@styles/image-gallery-style.scss';

import { AuthModalWrapper } from '../app/components/layouts';
import store from '../app/redux/store';
import theme from '../styles/theme';

dayjs().locale('vi');

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AuthModalWrapper>
          <Component {...pageProps} />
        </AuthModalWrapper>
      </ChakraProvider>
    </Provider>
  );
}

export default MyApp;
