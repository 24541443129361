import Link from 'next/link';

import logoWithText from '@assets/logo-with-text.svg';
import logo from '@assets/logo.svg';

import ShimmerImage from '../ShimmerImage';

interface Props {
  size?: string;
  width?: string;
  height?: string;
  withText?: boolean;
}

const Logo: React.FC<Props> = (props) => {
  const { size = '64px', width, height, withText } = props;
  return (
    <Link href="/">
      <a>
        <ShimmerImage
          src={withText ? logoWithText : logo}
          alt="Sherdtrip Logo"
          width={width || size}
          height={height || size}
          priority
          cursor="pointer"
        />
      </a>
    </Link>
  );
};

export default Logo;
