import { createContext, useContext } from 'react';

import { AuthModalType } from '../components/modules/auth/AuthModal';

type AuthModalContextType = {
  open: (modalType: AuthModalType, modalProps?: any) => void;
  close: () => void;
  store: any;
  isOpen: boolean;
};

const initalState: AuthModalContextType = {
  open: () => {},
  close: () => {},
  store: {},
  isOpen: false,
};

const AuthModalContext = createContext<AuthModalContextType>(initalState);

export const useAuthModalContext = () => useContext(AuthModalContext);
export default AuthModalContext;
