import { useRouter } from 'next/router';

import { Avatar, Button, Container, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { LogOutCircle, User, Wrench } from '@styled-icons/boxicons-regular';
import { DownArrow } from '@styled-icons/boxicons-solid';

import { useAuthModalContext } from '@contexts/AuthModalContext';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { logout } from '@redux/authReducer';

import Logo from '@common/Logo';
import { AuthModalType } from '@modules/auth/AuthModal';

import styles from '@styles/export.module.scss';

import NavBarTab from './NavBarTab';

const NavBar: React.FC = () => {
  const router = useRouter();
  const { open, store: authData } = useAuthModalContext();
  const { authStatus, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { isRestoring } = authData;

  const isLoggedin = authStatus === 'loggedin';
  const handleLogout = () => {
    dispatch(logout());
  };
  const handleOpenSetting = () => {
    router.push('/settings');
  };

  const handleOpenProfile = () => {
    router.push('/profile');
  };
  return (
    <Flex
      as="nav"
      zIndex="1000"
      position="fixed"
      top="0"
      w="100%"
      background={styles.colorPrimaryWhite}
      borderBottom="1px solid rgba(128, 130, 133, 0.3)"
    >
      <Container height="80px" display="flex" maxWidth="6xl" justifyContent="space-between" alignItems="center">
        <Logo height="40px" width="212px" withText />
        <Flex gap="4">
          <NavBarTab />
          {!isLoggedin ? (
            <Button
              onClick={() => open(AuthModalType.AuthMethod)}
              variant="outline"
              isLoading={isRestoring}
              isDisabled={isRestoring}
            >
              Đăng nhập
            </Button>
          ) : (
            <Menu matchWidth>
              <MenuButton
                as={Button}
                variant="ghost"
                borderRadius="full"
                rightIcon={<DownArrow size="16px" />}
                leftIcon={<Avatar size="xs" name={user?.displayName || ''} src={user?.photoURL || ''} />}
              >
                {user?.displayName}
              </MenuButton>
              <MenuList color="gray.700" width="100%" fontSize="18px" border="none">
                <MenuItem padding="10px 40px" fontWeight="600" icon={<User size="18px" />} onClick={handleOpenProfile}>
                  Tài khoản
                </MenuItem>
                <MenuItem
                  padding="10px 40px"
                  fontWeight="600"
                  icon={<Wrench size="18px" />}
                  onClick={handleOpenSetting}
                >
                  Cài đặt
                </MenuItem>
                <MenuItem
                  padding="10px 40px"
                  fontWeight="600"
                  color={styles.colorNotificationRed}
                  icon={<LogOutCircle size="18px" />}
                  onClick={handleLogout}
                >
                  Đăng xuất
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Container>
    </Flex>
  );
};

export default NavBar;
