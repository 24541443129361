import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { firebaseAuth } from 'app/firebase/firebase';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 12000;
axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_URL;

const getHttpHeaders = async (needAuthenticated = true): Promise<AxiosRequestConfig> => {
  const bearer = await firebaseAuth.currentUser?.getIdToken();
  if (!needAuthenticated && !bearer) return {};

  return {
    headers: {
      Authorization: `Bearer ${bearer}`,
    },
  };
};

const HttpClient = {
  get: async (path: string, { needAuthenticated = true, params = {} } = {}): Promise<AxiosResponse> => {
    const headers = await getHttpHeaders(needAuthenticated);
    return axios.get(path, { ...headers, params });
  },
  post: async (path: string, { data = {}, needAuthenticated = true, params = {} } = {}): Promise<AxiosResponse> => {
    const headers = await getHttpHeaders(needAuthenticated);
    return axios.post(path, data, { ...headers, params });
  },
  put: async (path: string, { data = {}, needAuthenticated = true, params = {} } = {}): Promise<AxiosResponse> => {
    const headers = await getHttpHeaders(needAuthenticated);
    return axios.put(path, data, { ...headers, params });
  },
  delete: async (path: string, { needAuthenticated = true, params = {} } = {}): Promise<AxiosResponse> => {
    const headers = await getHttpHeaders(needAuthenticated);
    return axios.delete(path, { ...headers, params });
  },
  patch: async (path: string, { data = {}, needAuthenticated = true, params = {} } = {}): Promise<AxiosResponse> => {
    const headers = await getHttpHeaders(needAuthenticated);
    return axios.patch(path, data, { ...headers, params });
  },
};

export default HttpClient;
