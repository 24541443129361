import { useEffect, useState } from 'react';

import Link from 'next/link';

import { Box, Button, Center, Text } from '@chakra-ui/react';
import { Google, FacebookCircle } from '@styled-icons/boxicons-logos';
import { MailSend } from '@styled-icons/boxicons-regular';

import styles from '../../../../../styles/export.module.scss';
import { LoginType } from '../../../../firebase/auth';
import { useAppDispatch } from '../../../../hooks';
import { loginSocial } from '../../../../redux/authReducer';
import CustomDivider from '../../../common/CustomDivider';
import Logo from '../../../common/Logo';

interface Props {
  children?: React.ReactNode;
  onLoginWithEmail: () => void;
}

const AuthMethod: React.FC<Props> = (props) => {
  const [loginType, setLoginType] = useState<LoginType>();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggingIn) return;
    setLoginType(undefined);
  }, [isLoggingIn]);

  const isMethodLoading = (type: LoginType) => isLoggingIn && loginType === type;
  const loginWithGoogle = async () => {
    setLoginType(LoginType.Google);
    setIsLoggingIn(true);
    await dispatch(loginSocial(LoginType.Google));
    setIsLoggingIn(false);
  };
  const loginWithFacebook = async () => {
    setLoginType(LoginType.Facebook);
    setIsLoggingIn(true);
    await dispatch(loginSocial(LoginType.Facebook));
    setIsLoggingIn(false);
  };
  const loginWithEmail = () => {
    setLoginType(LoginType.Email);
    props.onLoginWithEmail();
  };

  return (
    <Box textAlign="center" width="100%">
      <Center>
        <Logo />
      </Center>
      <Box pt="8" as="h1">
        Đăng nhập
      </Box>
      <Box py="6" color="#87898E" as="h3">
        Hãy đăng nhập để khám phá Sherdtrip
      </Box>
      <Button
        leftIcon={<MailSend size="18px" />}
        width="100%"
        isDisabled={isLoggingIn}
        isLoading={isMethodLoading(LoginType.Email)}
        onClick={loginWithEmail}
      >
        Đăng nhập với Email
      </Button>
      <CustomDivider />
      <Button
        variant="outline"
        leftIcon={<FacebookCircle size="16px" />}
        width="100%"
        isDisabled={isLoggingIn}
        isLoading={isMethodLoading(LoginType.Facebook)}
        onClick={loginWithFacebook}
      >
        Tiếp tục với Facebook
      </Button>
      <Button
        my="8"
        variant="outline"
        leftIcon={<Google size="16px" />}
        width="100%"
        isDisabled={isLoggingIn}
        isLoading={isMethodLoading(LoginType.Google)}
        onClick={loginWithGoogle}
      >
        Tiếp tục với Google
      </Button>
      <Text color={styles.colorSecondaryGrey}>
        {'Nếu bạn tiếp tục là bạn đã đọc và đồng ý với '}
        <Link href={''}>
          <a
            style={{
              textDecoration: 'underline',
            }}
          >
            Các điều khoản dịch vụ
          </a>
        </Link>
        {' và '}
        <Link href={''}>
          <a
            style={{
              textDecoration: 'underline',
            }}
          >
            Chính sách quyền riêng tư
          </a>
        </Link>
      </Text>
    </Box>
  );
};

export default AuthMethod;
