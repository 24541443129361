import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { TripActions } from '@constants/actions';
import TripService from 'app/api/TripService';

export interface TripActionState {
  likeCount: Record<string, number>;
  like: Record<string, boolean>;
  bookmark: Record<string, boolean>;
}

const initialState: TripActionState = {
  likeCount: {},
  like: {},
  bookmark: {},
};

export const addToBookmark = createAsyncThunk(TripActions.ADD_BOOKMARK, async (tripId: string) => {
  TripService.addBookmark(tripId);
  return tripId;
});
export const likeTrip = createAsyncThunk(TripActions.LIKE, async (tripId: string) => {
  TripService.like(tripId);
  return tripId;
});
export const setTripActionState = createAsyncThunk(
  TripActions.SET_STATE,
  async (payload: { tripId: string; like?: number; isLiked?: boolean; isBookmarked?: boolean }) => payload,
);

export const tripActionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(likeTrip.fulfilled, (state, action) => {
      const isLiked = state.like[action.payload] || false;
      state.likeCount[action.payload] += isLiked ? -1 : 1;
      state.like[action.payload] = !isLiked;
    })
    .addCase(setTripActionState.fulfilled, (state, action) => {
      const { tripId, like, isLiked, isBookmarked } = action.payload;
      const [oldLike, oldLikeCount, oldBookmark] = [
        state.like[tripId] || false,
        state.likeCount[tripId] || 0,
        state.bookmark[tripId] || false,
      ];
      state.like[tripId] = isLiked || oldLike;
      state.likeCount[tripId] = like || oldLikeCount;
      state.bookmark[tripId] = isBookmarked || oldBookmark;
    })
    .addCase(addToBookmark.fulfilled, (state, action) => {
      const tripId = action.payload;
      const isBookmarked = state.bookmark[tripId] || false;
      state.bookmark[tripId] = !isBookmarked;
    });
});
