import { useState } from 'react';

import Link from 'next/link';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useAppDispatch } from '@hooks/index';
import { signUp } from '@redux/authReducer';

import Logo from '@common/Logo';
import PasswordInput from '@components/common/PasswordInput';
import { signUpSchema } from '@constants/yupSchema';
import { getAuthErrorString as getAuthErrorMessage } from '@utils/index';

import styles from '@styles/export.module.scss';

interface Props {
  onSignIn: () => void;
}

const SignUp: React.FC<Props> = (props) => {
  const form = useForm({
    resolver: yupResolver(signUpSchema),
  });

  const {
    register,
    handleSubmit,
    setFocus,
    setError,
    formState: { errors, isSubmitting: isSigningUp },
  } = form;

  const [signUpError, setSignUpError] = useState<any>();
  const dispatch = useAppDispatch();

  const handleSignUp = async (data: any = {}) => {
    const { email, password } = data;
    setSignUpError(null);
    try {
      await dispatch(signUp({ email, password: password ?? '' })).unwrap();
    } catch (error: any) {
      const errorMsg = getAuthErrorMessage(error?.code || '');
      setSignUpError(errorMsg);
      setFocus('email');
      setError('email', {});
    }
  };

  const handleSignIn = () => {
    props.onSignIn();
  };

  return (
    <Box textAlign="center" width="100%" as="form" onSubmit={handleSubmit(handleSignUp)}>
      <Logo />
      <Box py="8" as="h1">
        Thông tin tài khoản
      </Box>
      <Flex mb="8" gap="6" flexDirection="column">
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <Input autoFocus type="email" placeholder="Hãy nhập email để đăng ký" {...register('email')} />
          <FormErrorMessage>{errors.email?.message?.toString()}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.name}>
          <FormLabel>Tên của bạn</FormLabel>
          <Input placeholder="Hãy nhập tên của bạn" {...register('name')} />
          <FormErrorMessage>{errors.name?.message?.toString()}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password} textAlign="left">
          <FormLabel>Mật khẩu</FormLabel>
          <PasswordInput placeholder="Hãy nhập mật khẩu" {...register('password')} />
          {errors.password ? (
            <FormErrorMessage>{errors.password?.message?.toString()}</FormErrorMessage>
          ) : (
            <FormHelperText>Mật khẩu phải có ít nhất 8 kí tự bao gồm cả chữ và số</FormHelperText>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.confirmedPassword}>
          <FormLabel>Xác nhận mật khẩu</FormLabel>
          <PasswordInput placeholder="Hãy nhập lại mật khẩu" {...register('confirmedPassword')} />
          <FormErrorMessage>{errors.confirmedPassword?.message?.toString()}</FormErrorMessage>
        </FormControl>
        <Box textAlign="left" flexDirection="column" display="flex">
          <FormControl isInvalid={!!errors.agreeTerm} pb="6">
            <Checkbox {...register('agreeTerm')}>
              <Text color={styles.colorSecondaryGrey}>
                {'Tôi đã đọc, hiểu và đồng ý với '}
                <Link href={''}>
                  <Box color={styles.colorPrimaryBlack} as="a">
                    Các điều khoản dịch vụ
                  </Box>
                </Link>
              </Text>
            </Checkbox>
            <FormErrorMessage>{errors.agreeTerm?.message?.toString()}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.agreePolicy}>
            <Checkbox {...register('agreePolicy')}>
              <Text color={styles.colorSecondaryGrey}>
                {'Tôi đã đọc, hiểu và đồng ý với '}
                <Link href={''}>
                  <Box color={styles.colorPrimaryBlack} as="a">
                    Chính sách quyền riêng tư
                  </Box>
                </Link>
              </Text>
            </Checkbox>
            <FormErrorMessage>{errors.agreePolicy?.message?.toString()}</FormErrorMessage>
          </FormControl>
        </Box>
        {signUpError && (
          <FormControl justifyContent="center" display="flex" isInvalid={!!signUpError}>
            <FormErrorMessage>{signUpError}</FormErrorMessage>
          </FormControl>
        )}
        <Button width="100%" isLoading={isSigningUp} disabled={isSigningUp} type="submit">
          Đăng nhập
        </Button>
      </Flex>
      <Text color={styles.colorSecondaryGrey}>
        {'Bạn đã có tài khoản? '}
        <Button variant="link" onClick={handleSignIn}>
          Đăng nhập
        </Button>
      </Text>
    </Box>
  );
};

export default SignUp;
