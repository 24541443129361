import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

import { SUser } from '@types';

import { AuthActions } from '../constants/actions';
import { AuthService, LoginType } from '../firebase/auth';

export interface AuthState {
  user?: SUser;
  error?: string;
  authStatus: 'idle' | 'loggedin' | 'anonymous';
}

const initialState: AuthState = {
  user: undefined,
  error: undefined,
  authStatus: 'idle',
};

export const loginSocial = createAsyncThunk(AuthActions.loginSocial, async (type: LoginType) => {
  try {
    const user = await AuthService.loginSocial(type);
    return user;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const signIn = createAsyncThunk(AuthActions.signIn, async (payload: { email: string; password: string }) => {
  try {
    const user = await AuthService.signInWithEmailAndPassword(payload.email, payload.password);
    return user;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const signUp = createAsyncThunk(AuthActions.signUp, async (payload: { email: string; password: string }) => {
  try {
    const user = await AuthService.signUpWithEmail(payload.email, payload.password);
    return user;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const restoreUser = createAsyncThunk(AuthActions.restoreUser, async (user: User) => {
  try {
    if (user.isAnonymous) throw Error('Anonymous User');
    //TODO: fetch user profile from API
    return user;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
});

export const logout = createAsyncThunk(AuthActions.logout, async () => {
  try {
    await AuthService.logout();
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
});

export const loginAnonymously = createAsyncThunk(AuthActions.loginAnonymously, async () => {
  try {
    const user = await AuthService.loginAnonymously();
    return user;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
});

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginSocial.fulfilled, (state, action) => {
      state.authStatus = 'loggedin';
      state.user = action.payload;
    })
    .addCase(loginSocial.rejected, (state, action) => {
      state.error = action.error.message;
    })
    .addCase(logout.fulfilled, (state) => {
      state.authStatus = 'idle';
      state.user = undefined;
    })
    .addCase(restoreUser.fulfilled, (state, action) => {
      state.authStatus = 'loggedin';
      state.user = action.payload;
    })
    .addCase(signIn.fulfilled, (state, action) => {
      state.authStatus = 'loggedin';
      state.user = action.payload;
    })
    .addCase(signIn.rejected, (state, action) => {
      state.error = action.error?.name;
    })
    .addCase(signUp.fulfilled, (state, action) => {
      state.authStatus = 'loggedin';
      state.user = action.payload;
    })
    .addCase(signUp.rejected, (state, action) => {
      state.error = action.error?.name;
    })
    .addCase(loginAnonymously.fulfilled, (state, action) => {
      state.authStatus = 'anonymous';
      state.user = action.payload;
    });
});
