import { PropsWithChildren, useEffect, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { User } from 'firebase/auth';
import { debounce } from 'lodash';

import AuthModalContext from '@contexts/AuthModalContext';
import { useAppDispatch } from '@hooks/index';
import { loginAnonymously, restoreUser } from '@redux/authReducer';

import AuthModal from '@modules/auth/AuthModal';
import { firebaseAuth } from 'app/firebase/firebase';

const AuthModalWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const [store, setStore] = useState({ isRestoring: true });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const debounceRestoreUser = debounce(async (user: User | null) => {
      setStore({ isRestoring: false });
      if (!user) {
        await dispatch(loginAnonymously());
      } else {
        await dispatch(restoreUser(user));
      }
    }, 400);

    const authSubscription = firebaseAuth.onAuthStateChanged((user) => {
      debounceRestoreUser(user);
    });
    return () => {
      authSubscription();
    };
  }, [dispatch]);

  return (
    <AuthModalContext.Provider value={{ store, isOpen, open: onOpen, close: onClose }}>
      {children}
      {isOpen && <AuthModal isOpen={isOpen} onClose={onClose} />}
    </AuthModalContext.Provider>
  );
};

export default AuthModalWrapper;
