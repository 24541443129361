import * as yup from 'yup';

import { maxFileSizeBytes } from '@constants/index';
import { PrcingInfo, TripType } from '@enums';
import { bytesToMB, parseNumber } from '@utils/index';

export const signInSchema = yup.object().shape({
  email: yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
  password: yup.string().required('Vui lòng nhập mật khẩu'),
});

export const signUpSchema = yup.object().shape({
  email: yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
  password: yup
    .string()
    .min(8, 'Mật khẩu phải có ít nhất 8 ký tự')
    .required('Vui lòng nhập mật khẩu')
    .test('isValiPassword', 'Mật khẩu phải bao gồm cả chữ và số', (value) => {
      const password = value || '';
      const hasNumber = /[0-9]/.test(password);
      const hasAtLeastOneCharNotNumber = /[^0-9]/.test(password);
      return hasNumber && hasAtLeastOneCharNotNumber;
    }),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Mật khẩu không khớp')
    .required('Vui lòng nhập lại mật khẩu'),
  name: yup.string().required('Vui lòng nhập tên'),
  agreeTerm: yup.boolean().oneOf([true], 'Vui lòng đồng ý với Các điều khoản'),
  agreePolicy: yup.boolean().oneOf([true], 'Vui lòng đồng ý với Chính sách về quyền riêng tư'),
});

export const createTripSchema = yup.object().shape({
  thumbnail: yup.mixed().test({
    message: `Ảnh bìa không được có dung lượng lớn hơn ${bytesToMB(maxFileSizeBytes)}MB`,
    test: (file, context) => {
      if (!file) {
        return context.createError({ path: 'thumbnail', message: 'Vui lòng chọn ảnh bìa' });
      }
      return file?.size <= maxFileSizeBytes;
    },
  }),
  name: yup.string().max(100, 'Tên chuyến đi không được quá 100 ký tự').required('Vui lòng nhập tên chuyến đi'),
  type: yup.mixed<TripType>().oneOf([TripType.DayTrip, TripType.LongTrip]).required('Vui lòng chọn loại chuyến đi'),
  description: yup.string().required('Vui lòng nhập mô tả'),
  length: yup.object().shape({
    days: yup
      .number()
      .typeError('Vui lòng nhập số ngày')
      .when('type', {
        is: TripType.LongTrip,
        then: yup.number().required('Vui lòng nhập số ngày').min(1, 'Số ngày phải lớn hơn 0'),
      }),
  }),
  pricingInfo: yup
    .mixed<PrcingInfo>()
    .oneOf([PrcingInfo.Total, PrcingInfo.PerPerson, PrcingInfo.NotMentioned])
    .default(PrcingInfo.Total)
    .required('Vui lòng chọn loại giá'),
  price: yup.mixed().when('pricingInfo', {
    is: PrcingInfo.Total || PrcingInfo.PerPerson,
    then: yup
      .mixed()
      .test('type', 'Vui lòng nhập chi phí', (value) => {
        const number = parseNumber(value);
        return !isNaN(number);
      })
      .typeError('')
      .required('Vui lòng nhập chi phí'),
  }),
  numberOfPeople: yup.mixed().when('pricingInfo', {
    is: PrcingInfo.Total || PrcingInfo.PerPerson,
    then: yup
      .number()
      .required('Vui lòng nhập số người')
      .min(1, 'Số người phải lớn hơn 0')

      .typeError('Vui lòng nhập số người'),
  }),
  tags: yup.array().of(yup.string()).required('Vui lòng chọn ít nhất 1 thẻ'),
  bestMonths: yup.array().of(yup.number()).optional(),
  socialLinks: yup.array().of(yup.string()).optional(),
  transportations: yup.array().of(yup.string()).optional(),
});
