export const AuthActions = {
  restoreUser: 'auth/restoreUser',
  signIn: 'auth/signIn',
  signUp: 'auth/signUp',
  loginSocial: 'auth/loginSocial',
  logout: 'auth/logout',
  loginAnonymously: 'auth/loginAnonymously',
};

export enum TripActions {
  LIKE = 'LIKE',
  SET_STATE = 'SET_STATE',
  ADD_BOOKMARK = 'ADD_BOOKMARK',
}
