export enum TripType {
  DayTrip = 'Day trip',
  LongTrip = 'Long trip',
}

export enum PriceRange {
  Low = 'Low',
  High = 'High',
}

export enum PrcingInfo {
  Total = 'Total',
  PerPerson = 'Per person',
  NotMentioned = 'Not mentioned',
}
