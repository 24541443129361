export const ApiEnpoints = {
  trips: {
    default: '/trips',
    detail: (id: string) => `/trips/${id}`,
    populars: '/trips/sections/populars',
    bestOfTheMonth: '/trips/sections/best-of-the_month',
    search: '/trips/search',
    like: (id: string) => `trips/${id}/like`,
    bookmark: (id: string) => `trips/${id}/bookmark`,
  },
  user: {
    bookmarkedTrips: 'users/bookmarked',
    myTrips: 'users/my-trips',
    trips: (id: string) => `/users/${id}/trips`,
    default: '/users',
  },
  tags: {
    default: '/tags',
  },
};
