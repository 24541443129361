import { useState } from 'react';

import { Button, Input, InputGroup, InputProps, InputRightElement, forwardRef } from '@chakra-ui/react';

import styles from '@styles/export.module.scss';

const PasswordInput = forwardRef<InputProps, 'input'>((props, ref) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow((previousState) => !previousState);
  return (
    <InputGroup>
      <Input type={show ? 'text' : 'password'} {...props} ref={ref} />
      <InputRightElement width="fit-content">
        <Button
          width="64px"
          height="100%"
          variant="ghost"
          px="4"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          background={styles.colorSecondaryGrey}
          color={styles.colorPrimaryWhite}
          fontSize="16px"
          fontWeight="400"
          _hover={{ fontWeight: '500' }}
          onClick={handleClick}
        >
          {show ? 'Ẩn' : 'Xem'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});

export default PasswordInput;
