import { configureStore } from '@reduxjs/toolkit';

import { reducer } from './reducer';

export const store = configureStore({
  reducer,

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
